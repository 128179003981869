// @codekit-append "../node_modules/foundation-sites/dist/js/foundation.min.js";
// @codekit-append "../bower_components/slick-carousel/slick/slick.js";
// @codekit-append "../bower_components/isotope-layout/dist/isotope.pkgd.js";


jQuery(document).ready(function() {
    jQuery(document).foundation();

    if(jQuery(".slider-startseite-intro").length) {
        jQuery('.slider-startseite-intro').slick( {
            arrows: false,
            dots: true,
            autoplay: true,
            autoplaySpeed: 5000,
            pauseOnHover: false,
            infinite: true,
            speed: 500,
            fade: false,
            cssEase: 'linear'
        });

        jQuery(".slider-startseite-intro").on("afterChange", function(event, slick, currentSlide) {
            currentSlide = currentSlide+1;
            if(currentSlide < 10)
                currentSlide = "0"+currentSlide;
            jQuery(".current-slide").html(currentSlide);
        });
    }

    if(jQuery(".teaser-slider-1").length) {
        jQuery('.teaser-slider-1').slick( {
            arrows: false,
            dots: false,
            autoplay: true,
            autoplaySpeed: 5000,
            pauseOnHover: false,
            infinite: true,
            speed: 500,
            fade: true,
            cssEase: 'linear'
        });
    }
    if(jQuery(".teaser-slider-2").length) {
        jQuery('.teaser-slider-2').slick( {
            arrows: false,
            dots: false,
            autoplay: true,
            autoplaySpeed: 5000,
            pauseOnHover: false,
            infinite: true,
            speed: 500,
            fade: true,
            cssEase: 'linear'
        }).slick("pause");
        
        var initialDelay = 3000;
        setTimeout(function() {
            jQuery(".teaser-slider-2").slick("play");
        }, initialDelay);
    }

    if(jQuery(".slider-projekt-intro").length) {
        jQuery('.slider-projekt-intro').slick( {
            arrows: false,
            dots: true,
            autoplay: true,
            autoplaySpeed: 5000,
            pauseOnHover: false,
            infinite: true,
            speed: 500,
            fade: false,
            cssEase: 'linear'
        });

        jQuery(".slider-projekt-intro").on("afterChange", function(event, slick, currentSlide) {
            currentSlide = currentSlide+1;
            if(currentSlide < 10)
                currentSlide = "0"+currentSlide;
            jQuery(".current-slide").html(currentSlide);
        });
    }

    jQuery(document).on("click", ".scroll-down-bar", function() {
        var offsetTop = 0;
        if(jQuery(".modul--startseite-intro").length) {
            offsetTop = jQuery(".modul--startseite-intro").height();
        }
        if(jQuery(".modul--projekt-intro").length) {
            offsetTop = jQuery(".modul--projekt-intro").height();
        }
        if(jQuery(".modul--subsite-intro").length) {
            offsetTop = jQuery(".modul--subsite-intro").height();
        }

		jQuery('html, body').animate({
			scrollTop: offsetTop
		}, 'slow');
    });
    jQuery(document).on("click", ".to-top", function() {
		jQuery('html, body').animate({
			scrollTop: 0
		}, 'slow');
    });
    
    if(jQuery(".projekte").length) {
        setTimeout(function() {
            var $grid = jQuery('.projekte').isotope({
                itemSelector: '.projekt-container',
                layoutMode: 'fitRows',
                getSortData: {
                    name: '.name',
                    symbol: '.symbol',
                    number: '.number parseInt',
                    category: '[data-category]'
                }
            });
            // bind filter button click
            jQuery(document).on('click', '.projekte-filter', function() {
                var $clickedFilter = jQuery(this);
                var filterValue = $clickedFilter.attr('data-filter');
                jQuery(".projekte-filter").removeClass("active");
                $clickedFilter.addClass("active");
                if(filterValue != "*") {
                    $grid.isotope({ filter: "."+filterValue });
                } else {
                    $grid.isotope({ filter: filterValue });
                }
            });
        }, 500);
    }

    jQuery(document).on("click", ".submit-kontakt", function() {
        var status = true;
        jQuery(".req, #kontakt_datenschutz_text").removeClass("error");
        jQuery(".submit-success, .submit-error").fadeOut("fast");

        var unternehmen = jQuery("#kontakt_unternehmen").val();
        var name = jQuery("#kontakt_name").val();
        var telefon = jQuery("#kontakt_telefon").val();
        var strasse = jQuery("#kontakt_strasse").val();
        var plzort = jQuery("#kontakt_plzort").val();
        var email = jQuery("#kontakt_email").val();
        var nachricht = jQuery("#kontakt_nachricht").val();
        var datenschutz = (jQuery("#kontakt_datenschutz").is(":checked")) ? 1 : 0;

        jQuery.each(jQuery(".req"), function() {
            if(jQuery(this).val() == "") {
                status = false;
                jQuery(this).addClass("error");
            }
        });
        
        if(datenschutz === 0) {
            status = false;
            jQuery("#kontakt_datenschutz_text").addClass("error");
        }

        if(status) {
            jQuery.ajax({
                type: "POST",
                url: "/sites/all/themes/omas/templates/submit-kontakt.php",
                data: {
                    'unternehmen': unternehmen,
                    'name': name,
                    'telefon': telefon,
                    'strasse': strasse,
                    'plzort': plzort,
                    'email': email,
                    'nachricht': nachricht,
                    'datenschutz': datenschutz,
                },
                dataType: 'json',
                cache: false,
                beforeSend: function() {
                    jQuery(".submit-kontakt").fadeOut("fast");
                },
                success: function(data) {
                    if(data.status) {
                        jQuery(".submit-success").html("Ihre Anfrage wurde erfolgreich übermittelt.").fadeIn("fast");
                    } else {
                        jQuery(".submit-kontakt").fadeIn("fast");
                        jQuery(".submit-error").html(data.message).fadeIn("fast");
                    }
                },
                error: function() {
                    jQuery(".submit-kontakt").fadeIn("fast");
                    jQuery(".submit-error").html("Beim Versand der Anfrage ist ein technisches Problem aufgetreten.").fadeIn("fast");
                }
            });
        } else {
            jQuery(".submit-error").html("Bitte geben Sie Ihren Namen, Ihre E-Mail Adresse sowie eine Nachricht ein und bestätigen Sie unsere Datenschutzbestimmungen.").fadeIn("fast");
        }
    });

    jQuery(document).on("click", ".info-open-close.text", function() {
        jQuery(".hidden-text").slideToggle();
        jQuery(this).toggleClass("open");
    });
    jQuery(document).on("click", ".info-open-close.projekte", function() {
        jQuery("section.modul--projekte").slideToggle();
        jQuery(this).toggleClass("open");
    });
});

